import React from 'react';
import PropTypes from 'prop-types';
import loadImage from '../../utils/loadImage';
import * as style from './videoBackground.module.scss';

class VideoBackground extends React.Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
      usePoster: false,
    };
  }
  componentDidMount() {
    this.video.load();
    if (this.video.readyState >= 3) {
      this.handleLoaded();
    }
    this.posterTimeout = setTimeout(this.checkPoster, 4000);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.paused !== nextProps.paused) {
      if (this.props.paused) {
        this.video.pause();
      } else {
        this.video.play();
      }
    }
  }
  componentDidUpdate(prevProps) {
    // if src changes, need to call load method ¯\_(ツ)_/¯
    if (prevProps.src !== this.props.src) {
      this.video.load();
    }
  }
  componentWillUnmount() {
    clearTimeout(this.posterTimeout);
  }

  handleLoaded = e => {
    if (!this.state.loaded) {
      this.setState({ loaded: true });
    }
  };
  // if video hasn't started playing use poster
  checkPoster = () => {
    if (this.video.currentTime === 0) {
      this.setState({ usePoster: true });
    }
  };
  getPoster() {
    return this.props.poster ? this.props.poster : '';
  }
  render() {
    const display = this.state.loaded && !this.props.hidden;
    const loadStyle = {
      opacity: display ? this.props.opacity : 0,
    };

    return (
      <video
        ref={el => {
          this.video = el;
        }}
        className={`opacity ${
          this.props.videoStyle === 'cover'
            ? style.videoCover
            : style.videoNormal
        }  ${style.videoLoad} ${this.props.slowFade ? style.slowFade : ''}`}
        loop
        autoPlay
        playsInline
        muted
        style={loadStyle}
        onCanPlay={this.handleLoaded}
        src={this.props.src}
        poster={this.state.usePoster ? this.getPoster() : ''}
      />
    );
  }
}

VideoBackground.defaultProps = {
  opacity: 1,
  hidden: false,
};

VideoBackground.propTypes = {
  src: PropTypes.string.isRequired,
  videoStyle: PropTypes.string,
  opacity: PropTypes.number,
  hidden: PropTypes.bool,
};

export default VideoBackground;
