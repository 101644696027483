import React, { Component } from 'react';
import EffectCard from './effectCard';
import * as style from './regionDetail.module.scss';
import TopLink from './topLink';

class RegionDetail extends Component {
  state = {
    activeCardIndex: null,
  };
  handleKey = e => {
    if (e.key === 'Escape') {
      this.props.regionToMap();
    }
  };
  componentDidMount() {
    window.addEventListener('keydown', this.handleKey);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKey);
  }
  handleCardOver = cardIndex => {
    this.setState({
      activeCardIndex: cardIndex,
    });
  };
  handleCardOut = () => {
    this.setState({
      activeCardIndex: null,
    });
  };

  render() {
    return (
      <div className={style.regionDetail}>
        <div className={style.regionDetailInner}>
          <TopLink handleClick={this.props.regionToMap} text="Back To Map" />
          <div className={`${style.regionDetailScroll} regionDetail__content`}>
            <header className={style.regionHeader}>
              <h2>{this.props.regionData.title}</h2>
            </header>
            <div className={style.effectCards}>
              {this.props.regionData.paragraphs.map((data, i) => (
                <EffectCard
                  {...data}
                  isActive={i === this.state.activeCardIndex}
                  isOtherActive={
                    this.state.activeCardIndex !== null &&
                    this.state.activeCardIndex !== i
                  }
                  key={`card${i}`}
                  handleCardOver={this.handleCardOver}
                  handleCardOut={this.handleCardOut}
                  index={i}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegionDetail;
