import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as style from './effectCard.module.scss';
import CircleButton from '../ui/circleButton';
import * as buttonStyle from '../ui/circleButton.module.scss';

class EffectCard extends Component {
  render() {
    return (
      <a
        href={this.props.related_impact_articles[0].path}
        className={`${style.card} ${buttonStyle.buttonHoverContainer} ${
          this.props.isActive ? style.active : ''
        } ${this.props.isOtherActive ? style.otherActive : ''}`}
        onMouseOver={() => {
          this.props.handleCardOver(this.props.index);
        }}
        onMouseOut={this.props.handleCardOut}
      >
        <div className={style.cardBk}>
          <div
            style={{
              backgroundImage: `url(${this.props.image.sizes.square_md})`,
            }}
          />
        </div>
        <div className={style.cardContent}>
          <h3>{this.props.callout_heading}</h3>
          <div
            className={style.text}
            dangerouslySetInnerHTML={{ __html: this.props.text }}
          />
        </div>
        <div className={style.icon}>
          <CircleButton type="open" />
          <div className={`${style.iconText} f-label`}>
            More On {this.props.related_impact_articles[0].title}
          </div>
        </div>
      </a>
    );
  }
}

export default EffectCard;
