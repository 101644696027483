import React, { Component } from 'react';
import Transitioner from '../ui/transitioner';
import MapRegionSVG from './mapRegionSVG';
import SelectorPin, { MobileLabel } from './selectorPin';
import * as style from './climateMap.module.scss';
import mapBK from '../../assets/climate-map.svg';

class ClimateMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noMapPan: this.props.viewRegion,
      pinTransition: true,
      regionTransform: {
        x: 0,
        y: 0,
      },
      isTouch: false,
    };
    this.regionRefs = {};
    this.isTouching = false;
    this.mouseAdjust = {
      x: 0,
      y: 0,
    };
    this.pointerX = 0;
    this.pointerY = 0;
    this.deltaX = 0;
    this.deltaY = 0;
    this.mapDeltaX = 0;
    this.mapDeltaY = 0;
  }
  componentDidMount() {
    if (this.props.viewRegion) {
      setTimeout(() => {
        this.setState({ noMapPan: false });
      }, 10);
    }
    if (this.props.viewRegion) {
      this.regionTransform();
    }
    this.touchTarget.addEventListener('touchstart', this.handleMouseDown, {
      passive: false,
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.viewRegion && !nextProps.viewRegion) {
      this.setState(
        {
          regionTransform: { x: 0, y: 0 },
        },
        () => {
          this.backToStart();
        }
      );
    } else if (!this.props.viewRegion && nextProps.viewRegion) {
      if (nextProps.regionSelected !== null) {
        this.regionTransform();
      }
    }
  }
  componentWillUnmount() {
    this.touchTarget.removeEventListener('touchstart', this.handleMouseDown, {
      passive: false,
    });
  }
  backToStart = () => {
    this.nextX = 0;
    this.nextY = 0;
    this.pointerX = 0;
    this.pointerY = 0;
    this.deltaX = 0;
    this.deltaY = 0;
    this.mapDeltaX = 0;
    this.mapDeltaY = 0;
    this.mouseAdjust = {
      x: 0,
      y: 0,
    };
    this.updatePositions();
  };
  mapMove = () => {
    const newDate = Date.now();
    const doMap = newDate - this.prevDate >= 100;
    if (doMap) {
      this.prevDate = newDate;
    }
    const target = document.elementFromPoint(this.pointerX, this.pointerY);
    let foundSelection = false;
    if (target && target.id && target.id.indexOf('climateMapRegion') !== -1) {
      const index = target.id.replace('climateMapRegion', '');
      this.props.selectRegion(parseInt(index, 10));
    } else {
      if (this.props.regionSelected !== null) {
        this.props.deselectRegion();
      }
    }
    this.nextX = this.pointerX - this.startX;
    this.nextY = this.pointerY - this.startY;

    this.nextMapX = this.pointerX - this.originX;
    this.nextMapY = this.pointerY - this.originY;

    this.deltaX = this.nextX;
    this.deltaY = this.nextY;
    this.mapDeltaX = doMap ? this.nextMapX : this.mapDeltaX;
    this.mapDeltaY = doMap ? this.nextMapY : this.mapDeltaY;
    this.updatePositions();
    this.mapRAF = requestAnimationFrame(this.mapMove);
  };
  updatePositions = () => {
    this.pin.style.cssText = `transform: translate3d(${this.deltaX +
      this.mouseAdjust.x}px, ${this.deltaY + this.mouseAdjust.y}px, 0)`;

    this.mapMover.style.cssText = this.getTransform();
  };
  handleMouseDown = e => {
    e.preventDefault();
    if (e.type === 'touchstart' && !this.state.isTouch) {
      this.setState({ isTouch: true });
    }
    if (
      this.props.viewRegion ||
      this.props.view !== 'LOCAL' ||
      this.state.showingRegionList ||
      this.isTouching
    ) {
      return;
    }
    this.isTouching = true;
    setTimeout(() => {
      this.setState({ pinTransition: false });
    }, 250);

    const pointer = e.type === 'touchstart' ? e.touches[0] : e;

    // distance from map pin mount to mouseup
    const pinRect = this.pin.getBoundingClientRect();
    this.mouseAdjust = {
      x: pointer.clientX - pinRect.left - 22.5,
      y: pointer.clientY - pinRect.top - 33.5,
    };

    this.props.onStartLocating();
    this.prevDate = Date.now();
    this.startX = pointer.clientX;
    this.startY = pointer.clientY;
    this.originX = window.innerWidth / 2;
    this.originY = window.innerHeight / 2;
    this.nextX = 0;
    this.nextY = 0;

    if (e.type === 'touchstart') {
      window.addEventListener('touchend', this.handleMouseUp);
      window.addEventListener('touchmove', this.handleMouseMove);
    } else {
      window.addEventListener('mouseup', this.handleMouseUp);
      window.addEventListener('mousemove', this.handleMouseMove);
    }
    this.handleMouseMove(e);

    this.mapRAF = requestAnimationFrame(this.mapMove);
  };
  handleMouseUp = e => {
    if (e.type === 'touchend' && e.touches.length > 0) {
      return;
    }
    cancelAnimationFrame(this.mapRAF);
    this.setState({ pinTransition: true });
    if (e.type === 'touchend') {
      window.removeEventListener('touchend', this.handleMouseUp);
      window.removeEventListener('touchmove', this.handleMouseMove);
    } else {
      window.removeEventListener('mouseup', this.handleMouseUp);
      window.removeEventListener('mousemove', this.handleMouseMove);
    }
    if (this.props.regionSelected === null) {
      this.backToStart();
    }
    this.props.onFinishLocating();
    this.isTouching = false;
  };

  handleMouseMove = e => {
    if (this.props.showMapText) {
      this.props.hideMapText();
    }
    if (
      !this.props.locating &&
      (e.type !== 'touchstart' && e.type !== 'mousedown')
    ) {
      return;
    }
    const pointer =
      e.type === 'touchmove' || e.type === 'touchstart' ? e.touches[0] : e;
    this.pointerX = pointer.clientX;
    this.pointerY = pointer.clientY;
  };

  getPinRef = el => {
    this.pin = el;
  };

  regionTransform = () => {
    this.backToStart();
    const activeRegionIndex =
      this.props.regionSelected !== null
        ? this.props.regionSelected
        : this.props.regionHighlighted;

    if (typeof activeRegionIndex === 'undefined') {
      return;
    }

    const mapRect = this.mapSVG.getBoundingClientRect();
    const mapLeft = mapRect.left;
    const mapTop = mapRect.top;
    let mapWidth = mapRect.width;
    let mapHeight = mapRect.height;

    const activeRegionRect = this.regionRefs[
      activeRegionIndex
    ].getBoundingClientRect();

    let regionWidth = activeRegionRect.width;
    let regionHeight = activeRegionRect.height;
    let regionLeft = activeRegionRect.left - mapLeft;
    let regionTop = activeRegionRect.top - mapTop;

    if (!this.props.zoomed) {
      // difference between list view zoom level and zoomed in level
      const multiplier = window.innerWidth > 980 ? 1.529 : 1.8824;

      regionLeft *= multiplier;
      regionTop *= multiplier;
      regionWidth *= multiplier;
      regionHeight *= multiplier;
      mapWidth *= multiplier;
      mapHeight *= multiplier;
    }
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    const padTop = windowWidth < 980 ? 100 : 170;
    this.setState(
      {
        regionTransform: {
          x: (mapWidth - regionWidth) / 2 - regionLeft,
          y:
            (mapHeight - regionHeight) / 2 -
            regionTop -
            windowHeight / 2 +
            padTop,
        },
      },
      () => {
        this.updatePositions();
      }
    );
  };
  getMapRef = el => {
    this.mapSVG = el;
  };

  getRegionRef = (el, id) => {
    this.regionRefs[id] = el;
  };
  getTransform = () => {
    let transMultiplier = 1;
    if (typeof window !== 'undefined') {
      transMultiplier = transMultiplier = window.innerWidth > 950 ? 0.65 : 0.85;
    }
    return !this.props.viewRegion ||
      (typeof this.props.regionSelected === 'undefined' &&
        typeof this.props.regionHighlighted === 'undefined')
      ? `transform: translate3d(${-this.mapDeltaX * transMultiplier}px, ${-this
          .mapDeltaY * transMultiplier}px, 0)`
      : `transform: translate3d(${this.state.regionTransform.x}px, ${
          this.state.regionTransform.y
        }px, 0)`;
  };

  getViewClass() {
    if (this.props.view === 'INTRO') {
      return style.intro;
    } else if (this.props.view === 'LOCAL') {
      return style.local;
    } else if (this.props.view === 'NATIONAL') {
      return style.national;
    }
  }

  render() {
    return (
      <div className={style.climateMap}>
        <div className={`${style.mapFrame} ${this.getViewClass()}`}>
          <Transitioner
            isActive={this.props.showMapDragUI}
            timeout={1000}
            classNames="mapView"
          >
            <div className={style.mapUI}>
              <div
                className={
                  this.props.showMapText ? style.mapText : style.mapTextHidden
                }
              >
                <h1>Select a location</h1>
                <p>Drag map pin</p>
              </div>
              <MobileLabel
                hidden={this.props.hidePin}
                label={this.props.regionLabel}
                locating={this.props.locating}
              />
              <SelectorPin
                handleMouseDown={this.handleMouseDown}
                hidden={this.props.hidePin}
                label={this.props.regionLabel}
                getPinRef={this.getPinRef}
                locating={this.props.locating}
                regionSelected={this.props.regionSelected}
                pinTransition={this.state.pinTransition}
                isTouch={this.state.isTouch}
              />
            </div>
          </Transitioner>

          <div
            className={`${style.mapMover} ${
              this.state.noMapPan ? style.noTransition : ''
            }`}
            ref={el => {
              this.mapMover = el;
            }}
          >
            <div className={`${style.mapInner}`}>
              <div className={style.mapScaler}>
                <div
                  ref={el => {
                    this.touchTarget = el;
                  }}
                  className={style.regions}
                  onMouseDown={this.handleMouseDown}
                >
                  <MapRegionSVG
                    isLocating={this.props.locating}
                    handleSelect={this.props.selectRegion}
                    handleDeselect={this.props.deselectRegion}
                    regionData={this.props.regionData}
                    regionSelected={this.props.regionSelected}
                    regionHighlighted={this.props.regionHighlighted}
                    getRegionRef={this.getRegionRef}
                    getMapRef={this.getMapRef}
                  />
                </div>
                <div className={style.mapBk} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClimateMap;
