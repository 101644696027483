import React from 'react';
import * as style from './nationalView.module.scss';
import CircleButton from '../ui/circleButton';
import * as buttonStyle from '../ui/circleButton.module.scss';

const ImpactCard = props => (
  <div className={`${style.impactCard} ${buttonStyle.buttonHoverContainer}`}>
    <a
      href={props.path}
      style={{ backgroundImage: `url(${props.image.sizes.square_sm})` }}
    >
      <span>{props.title}</span>
      <div className={style.circleWrap}>
        <CircleButton type="open" />
      </div>
    </a>
  </div>
);
const NationalImpact = props => (
  <div className={style.impactSection}>
    <div className={style.impactCards}>
      {props.related_impact_articles.map((impact, i) => (
        <ImpactCard key={`impactCard${i}`} {...impact} />
      ))}
    </div>
  </div>
);

const NationalView = props => {
  return (
    <div className={`${style.nationalView} `}>
      <header className="typeFonts1">
        <h1>{props.impactsData.title}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: props.impactsData.short_description,
          }}
        />
      </header>
      <div>
        {props.impactsData.paragraphs.map((impact, i) => (
          <NationalImpact key={`impact${i}`} {...impact} />
        ))}
      </div>
    </div>
  );
};

export default NationalView;
