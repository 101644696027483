import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Transitioner = props => (
  <TransitionGroup>
    {props.isActive && (
      <CSSTransition timeout={props.timeout} classNames={props.classNames}>
        {props.children}
      </CSSTransition>
    )}
  </TransitionGroup>
);

export default Transitioner;
