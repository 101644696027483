import React from 'react';
import LocationPin from '../ui/icons/locationPin';
import * as style from './climateMap.module.scss';
import TextAnimator from '../ui/textAnimator';

export const MobileLabel = props => (
  <div
    className={`${props.hidden ? style.mobileLabelHidden : style.mobileLabel} `}
  >
    <div className={style.pinLabel}>
      <TextAnimator
        text={props.label}
        show={props.label !== ''}
        style={{ width: '300px' }}
        delay
        fast
      />
    </div>
  </div>
);

const SelectorPin = props => (
  <div
    className={`${props.hidden ? style.mapPinHidden : style.mapPin} ${
      !props.pinTransition ? style.noPinTransition : ''
    } ${props.isTouch ? style.touch : ''} ${
      props.locating ? style.pinLocating : ''
    } ${props.regionSelected !== null ? style.pinSelected : ''}`}
    style={props.style}
    ref={el => {
      props.getPinRef(el);
    }}
  >
    <LocationPin />
    <div className={`${style.pinLabel} ${style.pinLabelLarge}`}>
      <TextAnimator
        text={props.label}
        show={props.label !== ''}
        style={{ width: '200px' }}
        delay
        fast
      />
    </div>
  </div>
);

export default SelectorPin;
