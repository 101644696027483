import React from 'react';
import CircleButton from '../ui/circleButton';
import * as style from './topLink.module.scss';

const TopLink = props => (
  <div className={props.padded ? style.topLinkPadded : style.topLink}>
    <CircleButton
      type="left"
      handleClick={props.handleClick}
      text={props.text}
      textWhite
      textRight
    />
  </div>
);

export default TopLink;
