import React, { Component } from 'react';
import * as style from './introView.module.scss';
import VideoBackground from '../basic/videoBackground';
import Button from '../ui/button';

const IntroView = props => (
  <div className={style.introView}>
    <div className={`${style.introContent} transition__frgrnd`}>
      <div className={style.introEnter}>
        <div>
          <span className={style.eyebrow}>
            Explore the <span className={style.orange}>Impacts</span>
          </span>
          <div dangerouslySetInnerHTML={{ __html: props.introText }} />
        </div>
        <div className={style.buttonWrap}>
          <Button
            color="whiteBorder"
            handleClick={props.goNational}
            text="National Trends"
          />
          <span className={style.buttonDivider} />
          <Button
            color="white"
            handleClick={props.goLocal}
            text="Local Impacts"
            icon="pin"
          />
        </div>
      </div>
    </div>
    <div className={`${style.bkVideo} transition__bk`}>
      <VideoBackground
        videoStyle="cover"
        src={props.videoSrc}
        slowFade
        poster={props.poster}
      />
    </div>
  </div>
);

export default IntroView;
