import { navigate } from '@reach/router';

export function getStartFromHash(hash, regionData) {
  let screen = 'INTRO';
  let region = null;

  if (hash === '#national') {
    screen = 'NATIONAL';
  } else if (hash === '#local') {
    screen = 'LOCAL';
  } else if (hash.indexOf('region') !== -1) {
    screen = 'LOCAL';
    region = getRegionFromHash(hash, regionData);
  }
  return {
    screen,
    region,
  };
}

export function getRegionFromHash(hash, regionData) {
  const activeRegionName = hash.split('-')[1];
  return regionData.findIndex(el => el.path.indexOf(activeRegionName) !== -1);
}

export function getStateFromHash(hash, showRegionList) {
  if (hash === '#national') {
    return {
      view: 'NATIONAL',
      showMapText: false,
    };
  } else if (hash === '#local') {
    return {
      view: 'LOCAL',
      showMapText: !showRegionList,
    };
  } else if (hash.indexOf('region') !== -1) {
    return {
      view: 'LOCAL',
    };
  } else {
    return {
      view: 'INTRO',
      showMapText: false,
    };
  }
}

export function addRegionHash(id, props) {
  navigate(`#region-${props.regionData[id].path.split('/')[3]}`)
}
