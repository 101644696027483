import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import * as style from './climateMap.module.scss';

const MapBkLayer = props => {
  let activeIndex = null;
  if (typeof props.regionSelected === 'number') {
    activeIndex = props.regionSelected;
  } else if (typeof props.regionHighlighted === 'number') {
    activeIndex = props.regionHighlighted;
  }

  return (
    <div className={style.background}>
      {props.regionData.map((region, i) => (
        <div
          key={`mapBk${i}`}
          className={
            activeIndex === i
              ? style.backgroundSlide
              : style.backgroundSlideHidden
          }
          style={{
            backgroundImage: `url(${
              props.regionData[i].featured_image.image.sizes.hero_md
            })`,
          }}
        />
      ))}
    </div>
  );
};

export default MapBkLayer;
