import React, { Component } from 'react';
import {
  getStartFromHash,
  getStateFromHash,
  addRegionHash,
} from './hashHelpers';

import LocalView from './localView';
import NationalView from './nationalView';
import IntroView from './introView';
import Transitioner from '../ui/transitioner';
import HeadTags from '../global/headTags';
import loadImage from '../../utils/loadImage';
import Button from '../ui/button';
import InfoButton from '../ui/infoButton';
import ClimateMap from './climateMap';
import SocialShare from '../global/socialShare';
import ClimateNav from './climateNav';
import InteractiveCredits from '../nukemap/interactiveCredits';
import * as style from './climateMap.module.scss';
import { globalHistory } from '@reach/router/lib/history';
import { navigate } from '@reach/router';

const viewRegionTimeout = 1150;
const pinTimeout = 400;

class Climate extends Component {
  constructor(props) {
    super(props);
    const start = getStartFromHash(
      this.props.location.hash,
      this.props.regionData
    );
    this.state = {
      view: start.screen,
      locating: false,
      regionSelected: start.region,
      regionHighlighted: null,
      viewRegion: start.region !== null,
      hidePin: start.region !== null,
      showMapText: start.region === null,
      regionToMap: false,
      regionLabel: '',
      mouseHidden: false,
      showRegionList: false,
      zoomed: start.region !== null,
      isViewingCredits: false,
    };
  }
  componentDidMount() {
    this.unlistenHistory = globalHistory.listen(this.handleHistory);
    if (this.props.location.hash.indexOf('#region') !== -1) {
      this.props.hideHeader();
    }
    this.props.regionData.forEach(data => {
      data.paragraphs.map(parData => loadImage(parData.image.sizes.square_md));
      loadImage(data.featured_image.image.sizes.hero_md);
    });
    // national trend cards
    this.props.data.nationalTrends.edges[0].node.paragraphs[0].related_impact_articles.forEach(
      data => loadImage(data.image.sizes.square_sm)
    );
  }
  componentWillUnmount() {
    this.unlistenHistory();
    window.removeEventListener('onkeyup', this.keyhandler);
  }

  handleHistory = location => {
    this.updateHashView(location.location.hash);
  };
  updateHashView = hash => {
    const newState = getStateFromHash(hash, this.state.showRegionList);
    if (hash === '#local' && this.state.viewRegion) {
      this.regionToMap();
    } else {
      this.setState(newState);
    }
  };

  onStartLocating = e => {
    this.props.hideHeader();
    clearTimeout(this.viewTimeout);
    this.setState({
      hidePin: false,
      locating: true,
      mouseHidden: true,
      showMapText: false,
      zoomed: true,
      regionToMap: false,
    });
  };

  hideMapText = () => {
    this.setState({ showMapText: false });
  };

  onFinishLocating = () => {
    if (this.state.regionSelected !== null) {
      this.viewRegion();
    } else {
      this.props.showHeader();
    }

    this.setState({
      locating: false,
      mouseHidden: false,
      zoomed: this.state.regionSelected !== null,
      showMapText: this.state.regionSelected === null,
    });
  };

  regionToMap = () => {
    this.props.showHeader();

    this.setState({
      regionSelected: null,
      regionHighlighted: null,
      viewRegion: false,
      regionToMap: true,
      zoomed: false,
    });
    clearTimeout(this.viewTimeout);
    this.viewTimeout = setTimeout(() => {
      this.setState({
        hidePin: false,
        regionToMap: false,
        showMapText: true,
      });
      /* this.props.history.push({
        pathname: this.props.location.pathname,
        hash: 'local',
      }); */
      navigate(`#local`);
    }, 1500);
  };
  viewRegion = () => {
    clearTimeout(this.viewTimeout);
    setTimeout(() => {
      this.setState({
        hidePin: true,
        showMapText: false,
      });
    }, pinTimeout);

    addRegionHash(this.state.regionSelected, this.props);

    this.viewTimeout = setTimeout(() => {
      this.setState({
        viewRegion: true,
        regionLabel: '',
      });
      this.props.hideHeader();
    }, viewRegionTimeout);
  };
  listToRegion = region => {
    addRegionHash(region, this.props);
    this.setState({
      showRegionList: false,
      viewRegion: true,
      regionLabel: '',
      regionHighlighted: null,
      regionSelected: region,
    });
  };
  selectRegion = id => {
    this.setState({
      regionSelected: id,
      regionLabel: this.props.regionData[id].title,
    });
  };
  deselectRegion = () => {
    this.setState({
      regionSelected: null,
      regionLabel: '',
    });
  };
  highlightRegion = id => {
    this.setState({
      regionHighlighted: id,
    });
  };
  showCredits = () => {
    this.setState({
      isViewingCredits: true,
      // showMapText: false,
      // hidePin: true,
    });
  };
  hideCredits = () => {
    this.setState({
      isViewingCredits: false,
      // showMapText: true,
      // hidePin: false,
    });
  };

  unhighlightRegion = () => {
    // don't unlighlight on exiting
    if (!this.state.showRegionList) {
      return;
    }
    this.setState({
      regionHighlighted: null,
    });
  };
  keyhandler = e => {
    // escape key closes region list overlay
    if (e.keyCode === 27 && this.state.showRegionList) {
      this.toggleRegionList();
    }
  };
  toggleRegionList = () => {
    clearTimeout(this.viewTimeout);
    if (!this.state.showRegionList) {
      window.addEventListener('keyup', this.keyhandler);
      this.props.hideHeader();
    } else {
      window.removeEventListener('keyup', this.keyhandler);
      this.props.showHeader();
    }
    this.setState({
      showMapText: this.state.showRegionList,
      hidePin: !this.state.showRegionList,
      showRegionList: !this.state.showRegionList,
      regionHighlighted: null,
    });
  };

  goNational = () => {
    clearTimeout(this.viewTimeout);
    // our transition code in gatsby browser causes delay, so also firing
    // state update directly
    /* this.props.history.push({
      pathname: this.props.location.pathname,
      hash: 'national',
    }); */
    navigate(`#national`);
    this.setState({
      view: 'NATIONAL',
      hidePin: false,
      regionToMap: false,
      showMapText: true,
    });
  };

  goLocal = () => {
    clearTimeout(this.viewTimeout);
    /* this.props.history.push({
      pathname: this.props.location.pathname,
      hash: 'local',
    }); */
    navigate(`#local`);
    this.setState({
      view: 'LOCAL',
      showMapText: true,
    });
  };

  getWrapperClasses = () => {
    return `${
      this.state.locating || this.state.regionSelected !== null
        ? style.zoom
        : ''
    } ${this.state.viewRegion ? style.viewRegion : ''} ${
      this.state.regionToMap ? style.regionToMap : ''
    } ${this.state.mouseHidden ? style.mouseHidden : ''}
    ${this.state.showRegionList ? style.showingRegionList : ''}
    `;
  };
  render() {
    const meta = (
      <HeadTags
        title={
          this.props.data.interactive.meta_title
            ? this.props.data.interactive.meta_title
            : this.props.data.interactive.title
        }
        description={this.props.data.interactive.meta_description}
        image={
          this.props.data.interactive.meta_image
            ? this.props.data.interactive.meta_image.sizes.hero_md
            : this.props.data.interactive.featured_image
              ? this.props.data.interactive.featured_image.image.sizes.hero_md
              : null
        }
        imageTwitter={
          this.props.data.interactive.meta_image
            ? this.props.data.interactive.meta_image.sizes.hero_sm
            : this.props.data.interactive.featured_image
              ? this.props.data.interactive.featured_image.image.sizes.hero_sm
              : null
        }
        location={this.props.location}
      />
    );
    // don't SSR, causing issues with map loading
    if (typeof window === 'undefined') {
      return <div className={style.climateTrends}>{meta}</div>;
    }
    return (
      <div className={style.climateTrends} style={this.props.style}>
        {meta}
        <div className={this.getWrapperClasses()}>
          <Transitioner
            isActive={this.state.view === 'INTRO'}
            timeout={1000}
            classNames="frgrndBckgrnd"
          >
            <IntroView
              goLocal={this.goLocal}
              goNational={this.goNational}
              videoSrc={this.props.data.interactive.video.url}
              poster={
                this.props.data.interactive.featured_image.image.sizes.hero_md
              }
              introText={this.props.data.interactive.intro_description}
            />
          </Transitioner>
          <Transitioner
            isActive={
              this.state.view !== 'INTRO' &&
              !this.state.showRegionList &&
              !this.state.locating &&
              this.state.regionSelected === null &&
              !this.state.viewRegion &&
              !this.state.isViewingCredits
            }
            timeout={1000}
            classNames="climateNavTrans"
          >
            <ClimateNav
              view={this.state.view}
              goNational={this.goNational}
              goLocal={this.goLocal}
            />
          </Transitioner>
          <ClimateMap
            regionData={this.props.regionData}
            regionPositions={this.props.regionPositions}
            regionSelected={this.state.regionSelected}
            regionHighlighted={this.state.regionHighlighted}
            regionLabel={this.state.regionLabel}
            onStartLocating={this.onStartLocating}
            onFinishLocating={this.onFinishLocating}
            selectRegion={this.selectRegion}
            deselectRegion={this.deselectRegion}
            locating={this.state.locating}
            hidePin={this.state.hidePin}
            hideMapText={this.hideMapText}
            viewRegionTimeout={viewRegionTimeout}
            showMapText={this.state.showMapText}
            view={this.state.view}
            viewRegion={this.state.viewRegion}
            zoomed={this.state.zoomed}
            showRegionList={this.state.showRegionList}
            showMapDragUI={
              this.state.view === 'LOCAL' && !this.state.isViewingCredits
            }
          />
          <Transitioner
            isActive={
              this.state.view === 'LOCAL' && !this.state.isViewingCredits
            }
            timeout={1000}
            classNames="mapView"
          >
            <div className={style.regionButtonWrap}>
              <Transitioner
                isActive={
                  !this.state.showRegionList &&
                  !this.state.locating &&
                  !this.state.hidePin &&
                  this.state.regionSelected === null
                }
                timeout={1000}
                classNames="fade"
              >
                <Button
                  handleClick={this.toggleRegionList}
                  color="whiteBorder"
                  text="Region List"
                />
              </Transitioner>
            </div>
          </Transitioner>
          <Transitioner
            isActive={
              this.state.view === 'LOCAL' && !this.state.isViewingCredits
            }
            timeout={1000}
            classNames="mapView"
          >
            <div className={style.utilityLinks}>
              <Transitioner
                isActive={
                  !this.state.showRegionList &&
                  !this.state.locating &&
                  !this.state.hidePin &&
                  this.state.regionSelected === null
                }
                timeout={1000}
                classNames="fade"
              >
                <div>
                  <div className={style.shareWrap}>
                    <SocialShare
                      static
                      horizontal
                      title={this.props.data.interactive.meta_title}
                    />
                  </div>
                  <InfoButton
                    handleClick={this.showCredits}
                    text="about"
                    showSm
                  />
                </div>
              </Transitioner>
            </div>
          </Transitioner>

          <Transitioner
            isActive={this.state.view === 'LOCAL'}
            timeout={1000}
            classNames="mapView"
          >
            <LocalView
              locating={this.state.locating}
              regionData={this.props.regionData}
              viewRegion={this.state.viewRegion}
              regionToMap={this.regionToMap}
              regionSelected={this.state.regionSelected}
              showRegionList={this.state.showRegionList}
              hidePin={this.state.hidePin}
              toggleRegionList={this.toggleRegionList}
              highlightRegion={this.highlightRegion}
              unhighlightRegion={this.unhighlightRegion}
              listToRegion={this.listToRegion}
              toggleRegionList={this.toggleRegionList}
              regionHighlighted={this.state.regionHighlighted}
            />
          </Transitioner>

          <Transitioner
            isActive={this.state.view === 'NATIONAL'}
            timeout={1000}
            classNames="mapView"
          >
            <NationalView
              impactsData={this.props.data.nationalTrends.edges[0].node}
            />
          </Transitioner>

          <InteractiveCredits
            reveal={this.state.isViewingCredits}
            hidden={false}
            toggleCredits={this.hideCredits}
            content={this.props.data.interactive.credit_body}
            hideHeader={this.props.hideHeader}
            showHeader={this.props.showHeader}
            addNelsonLogo
          />
        </div>
      </div>
    );
  }
}

export default Climate;
