import { graphql } from 'gatsby';
import React from 'react';
import Climate from '../components/climate/';
import regionPositions from '../components/climate/regionPositions';
import DefaultLayout from '../layouts/default-layout';

const ClimateTrends = props => (
  <DefaultLayout {...props}>
    <Climate style={props.transition && props.transition.style}
      {...props}
      regionData={props.data.regionData.edges.map(edge => ({
        ...edge.node,
        svgData: regionPositions[edge.node.region_id],
      }))}
    />
  </DefaultLayout>
);

export default ClimateTrends;

// eslint-disable-next-line
export const climateTrendsQuery = graphql`
query ClimateTrendsContentByPath(
    $path: String!,
    $idRegex: String!,
    $isEs: Boolean!,
    $blog: String!,
    $translations: String!
  ) {
  interactive: itemsJson(path: {eq: $path}) {
    title
    meta_title
    meta_description
    meta_image {
      sizes {
        hero_md
        hero_sm
      }
    }
    featured_image {
      image {
        sizes {
          hero_lg
          hero_md
          hero_sm
        }
      }
    }
    credit_body
    video {
      url
    }
    intro_description
  }
  nationalTrends: allItemsJson(
    filter: {content_type: {eq: "climate_trend"}, jsonId: {regex: $idRegex}}
  ) {
    edges {
      node {
        title
        short_description
        paragraphs {
          related_impact_articles {
            title
            path
            image {
              sizes {
                square_sm
              }
            }
          }
        }
      }
    }
  }
  regionData: allItemsJson(
    filter: {content_type: {eq: "region"}, jsonId: {regex: $idRegex}}
  ) {
    edges {
      node {
        id
        content_type
        title
        region_id
        path
        featured_image {
          image {
            sizes {
              hero_md
            }
          }
        }
        paragraphs {
          type
          callout_heading
          text
          related_impact_articles {
            title
            path
          }
          image {
            sizes {
              square_md
            }
          }
        }
      }
    }
  }
  ...headerFields @skip(if: $isEs)
  ...footerFields @skip(if: $isEs)
  ...headerFieldsEs @include(if: $isEs)
  ...footerFieldsEs @include(if: $isEs)
  challenges: allItemsJson(
    filter: {content_type: {regex: "/timeline|article|challenge/"}, jsonId: {regex: $idRegex}}
  ) {
    edges {
      node {
        title
        content_type
        path
        related_challenge {
          title
          path
        }
        core_curriculum {
          title
          path
        }
      }
    }
  }
  pages: allItemsJson(
    filter: {content_type: {eq: "page"}, jsonId: {regex: $idRegex}}
  ) {
    edges {
      node {
        title
        content_type
        path
      }
    }
  }
  blog: allItemsJson(filter: {jsonId: {eq: $blog}}) {
    edges {
      node {
        title
        content_type
        path
      }
    }
  }
  translations: allItemsJson(filter: {jsonId: {eq: $translations}}) {
    edges {
      node {
        translations {
          id
          key
          value
        }
      }
    }
  }
  megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
      projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
}
`;
