import React from 'react';
import * as style from './circleButton.module.scss';

const getClassName = type => {
  switch (type) {
    case 'play':
      return style.circlePlay;
    case 'close':
      return style.circleX;
    case 'open':
      return style.circlePlus;
    case 'left':
      return `${style.circleArrow} ${style.prev}`;
    default:
      return style.circleButton;
  }
};

const CircleButton = props => (
  <button
    className={`${getClassName(props.type)} ${
      props.bigger ? style.bigger : ''
    } ${props.smallLeft ? style.smallLeft : ''}
    ${props.biggest ? style.biggest : ''}`}
    onClick={props.handleClick}
    onMouseLeave={e => {
      // don't preserve focus state if mouseing out after click
      e.target.blur();
    }}
  >
    <span
      className={`${
        props.textRight ? style.buttonTextRight : style.buttonText
      } ${props.textStyleAlt ? style.textStyleAlt : ''} ${
        props.textWhite ? style.textWhite : ''
      } ${props.kicker ? style.hasKicker : ''}`}
    >
      {props.kicker && (
        <span className={`f-label ${style.kicker}`}>{props.kicker}</span>
      )}
      {props.text}
    </span>
  </button>
);
export default CircleButton;
