import React from 'react';
import Transitioner from '../ui/transitioner';
import RegionDetail from './regionDetail';
import RegionList from './regionList';
import MapBkLayer from './mapBkLayer';
import * as style from './climateMap.module.scss';

const LocalView = props => (
  <div
    className={`${style.localView} ${
      props.viewTransitioning ? style.viewTransitioning : ''
    }`}
  >
    <Transitioner
      isActive={props.viewRegion}
      timeout={2000}
      classNames="regionDetail"
    >
      <RegionDetail
        regionData={props.regionData[props.regionSelected]}
        regionToMap={props.regionToMap}
      />
    </Transitioner>

    <RegionList
      regions={props.regionData}
      highlightRegion={props.highlightRegion}
      unhighlightRegion={props.unhighlightRegion}
      viewRegion={props.viewRegion}
      show={props.showRegionList}
      listToRegion={props.listToRegion}
      closeList={props.toggleRegionList}
    />
    <MapBkLayer
      regionData={props.regionData}
      regionSelected={props.regionSelected}
      regionHighlighted={props.regionHighlighted}
    />
  </div>
);

export default LocalView;
