import React from 'react';
import * as style from './climateNav.module.scss';

const ClimateNav = props => (
  <div className={style.climateNav}>
    <button
      onClick={props.goNational}
      className={props.view === 'NATIONAL' ? style.navSelected : ''}
    >
      National
    </button>
    <span className={style.navDivider} />
    <button
      onClick={props.goLocal}
      className={props.view === 'LOCAL' ? style.navSelected : ''}
    >
      Local
    </button>
  </div>
);

export default ClimateNav;
