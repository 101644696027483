import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import TopLink from './topLink';

import * as style from './regionList.module.scss';

const RegionList = props => {
  return (
    <TransitionGroup>
      {props.show && (
        <CSSTransition timeout={750} classNames="modal">
          <div className={`${style.regionListWrap} modal__bk`}>
            <div className={`${style.listFrame} modal__inner`}>
              <TopLink
                handleClick={props.closeList}
                text="Back to Map"
                padded
                relative
              />
              <div className={`${style.regionListScroll} modal__content`}>
                <div className={style.regionListInner}>
                  <h2>Regions</h2>
                  <ul className={style.list}>
                    {props.regions.map((region, i) => (
                      <li key={`region${i}`}>
                        <button
                          onMouseEnter={() => {
                            props.highlightRegion(i);
                          }}
                          onMouseLeave={props.unhighlightRegion}
                          onTouchStart={() => {
                            props.highlightRegion(i);
                          }}
                          onClick={() => {
                            props.listToRegion(i);
                          }}
                        >
                          {region.title}{' '}
                          <span className={`icon-arrow ${style.arrow}`} />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

export default RegionList;
