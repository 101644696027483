// extracted by mini-css-extract-plugin
export var cardBk = "effectCard-module--cardBk--6U9Ba";
export var card = "effectCard-module--card--2wrmb";
export var cardContent = "effectCard-module--cardContent--5gj-V";
export var text = "effectCard-module--text--1-3vb";
export var active = "effectCard-module--active--bsxmF";
export var otherActive = "effectCard-module--otherActive--ECuEl";
export var icon = "effectCard-module--icon--DrqAD";
export var iconText = "effectCard-module--iconText--pXf8d";
export var slideUp = "effectCard-module--slideUp--Qt-Q1";
export var slideUpLarge = "effectCard-module--slideUpLarge--pLht7";